<template>
  <div class="first-step">

    <div style="margin-top: 29px;">
      <div>选择标准值作为唯一值，选择需要更新的信息作为更新值</div>
      <el-row :gutter="20" style="margin-top: 16px;">
        <el-col :span="12" style="display: flex; flex-direction: row;align-items: baseline;">
          <div style="width: 60px;">标准值 </div>
          <el-select v-model="importForm.standardValueList" multiple placeholder="请选择" @change="importForm.renewValueList = []">
            <el-option
              v-for="item in options.filter(i => i.type !== 'studentClass')"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12" style="display: flex; flex-direction: row;align-items: baseline;">
          <div style="width: 60px;">更新值 </div>
          <el-select v-model="importForm.renewValueList" multiple placeholder="请选择">
            <el-option
              v-for="item in options
                .filter(e => !importForm.standardValueList.includes(e.value) && e.value !== 'classes')
                .filter(e => !(importForm.standardValueList.includes('classes') && e.value === 'studentClass'))
              "
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>

    <div class="import-tips">
      <div class="tips1"><img src="../../../../assets/images/import/help.png" alt="" class="help-img">导入方法</div>
      <div>{{ importInfo.tips2 }}</div>
      <div>或是
        <el-button type="text" style="padding: 0 5px" @click="download">
          <img src="../../../../assets/images/import/download.png" alt="" class="download-img">
          下载模板
        </el-button>
        并按照模板填充数据，上传填好的文件</div>
    </div>

    <div class="excel-wrapper" :class="[fileName !== '' ? 'active-excel' : 'inactive-excel']">
      <div class="fileName">{{ fileName }}</div>
      <i class="el-icon-circle-close cancel-img" @click="delFile"></i>
    </div>

    <div class="upload-wrapper">
      <el-upload
        ref="upload"
        :action="action"
        :headers="headers"
        accept=".xlsx,.xls"
        :on-change="uploadChange"
        :before-remove="handleRemove"
        :with-credentials="true"
        :auto-upload="false"
        :on-exceed="handleExceed"
        :show-file-list="false"
        :limit="1"
      >
        <xk-button type="primary" :background-color="'#edf4fa'" :color="'#499df2'" plain class="upload-btn">上传文件</xk-button>
      </el-upload>
      <div>
        <div class="upload-tips">文件后缀名必须为xls或xlsx（即Excel格式)</div>
        <div class="upload-tips">文件大小不得大于10M。</div>
      </div>
    </div>

    <div style="text-align: center">
      <xk-button style="margin: 0" type="primary" @click="next">下一步</xk-button>
      <xk-button type="info" @click="cancel">取消</xk-button>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/libs/auth'
import { uploadAction } from '@/libs/settings'

export default {
  props: {
    schools: {
      type: Array,
    },
    options: {
      type: Array,
    },
    showDownload: {
      type: Boolean
    },
    importForm: {
      type: Object,
    },
    importInfo: {
      type: Object
    }
  },
  data() {
    return {
      fileName: ''
    }
  },
  computed: {
    action() {
      return uploadAction
    },
    headers() {
      return {
        'Authorization': getToken()
      }
    }
  },
  methods: {
    delFile() {
      this.fileName = ''
      this.$refs.upload.clearFiles()
    },
    next() {
      this.$eventDispatch('next')
    },
    cancel() {
      this.$eventDispatch('close')
    },
    download() {
      this.$eventDispatch('download')
    },
    uploadChange(file) {
        const isImg = ['.xlsx', '.xls'].includes(file.name.substring(file.name.lastIndexOf('.')))
        const isLt10M = file.size / 1024 / 1024 < 10
        const is50 = file.name.length < 30
        if (!isImg) {
            this.$message.error(`上传文件格式仅支持xlsx和xls!`);
            this.$refs.upload.clearFiles()
            return false
        }
        if (!is50) {
            this.$message.error('上传文件名称不能超过30个字符!');
            this.$refs.upload.clearFiles()
            return false
        }
        if (!isLt10M) {
            this.$message.error(`上传文件的大小不能超过10M!`)
            this.$refs.upload.clearFiles()
            return false
        }
      this.fileName = file.name
      this.$eventDispatch('uploadChange', file)
    },
    handleRemove() {
      this.$eventDispatch('uploadRemove')
    },
    handleExceed() {
      this.$message.warning('当前限制上传一个文件')
    }
  }
}
</script>

<style lang="scss" scoped>
.import-wrap {
  display: flex;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  height: 120px;
  margin: 20px 0;
  background-color: #fff;
  .icon-size {
    font-size: 40px;
  }
  .import-left-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #e6e6e6;
    width: 120px;
  }

  .import-right-content {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 10px 0 0 40px;
    .import-right-content-title {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
.upload-btn:hover {
  background-color: #59acff !important;
  color: #FFFFFF !important;
}
.first-step {
  width: 70%;
  margin: auto;
}
.import-tips {
  margin-top: 29px;
  text-align: left;
  height: 65px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  color: #595959;
  line-height: 24px;
  .tips1 {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
}
.help-img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.download-img {
  width: 11px;
  height: 13px;
  margin-right: 5px;
}
.active-excel {
  background-image: url('../../../../assets/images/import/excell.png');
}
.inactive-excel {
  background-image: url('../../../../assets/images/import/excel1.png');
}
.excel-wrapper {
  position: relative;
  margin: 48px 0 29px 0;
  width: 480px;
  height: 170px;
  background-color: #f5f7fa;
  //background-image: url('../../../../assets/images/import/excell.png');
  background-repeat: no-repeat;
  background-size: 20%;
  background-position: center 20%;
  .fileName {
    position: absolute;
    bottom: 28px;
    left: 50%;
    transform: translate(-50%);
    white-space: nowrap;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    color: #595959;
  }
  .cancel-img {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 20px;
    color: #c1cedb;
    &:hover {
      color: #faafaf;
    }
  }
}
.upload-wrapper {
  display: flex;
  margin-bottom: 15%;
  .upload-tips {
    margin-left: 16px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #A6A6A6;
    line-height: 22px;
  }
}

</style>
