<template>
  <el-upload class="avatar-uploader" :action="action" :headers="headers" :show-file-list="false"
    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
    <img v-if="src" :src="src" class="avatar">
    <i v-else  :class="['el-icon-plus avatar-uploader-icon', {current:  positionStatus}]" ></i>
  </el-upload>
</template>

<script>
  import {
    getToken
  } from '@/libs/auth';
  export default {
    props: {
      value: String,
      positionStatus: {
        type: Boolean,
        default: function () {
          return false
        }
      }
    },
    data() {
      return {
        src: ''
      }
    },
    computed: {
      action() {
        return '/api/school/common/uploadFile'
      },
      headers() {
        return {
          Authorization: getToken()
        }
      }
    },
    created() {
      this.src = this.value
    },
    methods: {
      // 学校LOGO上传
      handleAvatarSuccess(res, file) {
        const url = res.data.url
        this.src = url.replace('http://192.168.0.241:9000/example', 'http://124.71.235.241:9000/example')
        this.$emit('input', this.src)
      },
      // 上传校验
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 20;
        const is50 = file.name.length < 50
        if (!is50) {
          this.$message.error('上传文件名称不能超过50个字符!');
        }
        if (!isJPG && !isPNG) {
          this.$message.error('上传LOGO必须是JPG或PNG格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传LOGO图片大小不能超过20MB!');
        }
        return (isJPG || isPNG) && isLt2M && is50;
      },
    },
    watch: {
      value(val){
        this.src = this.value
      }
    }
  }
</script>

<style lang="scss" scoped>
/*.avatar-uploader {*/
/*  position: absolute;*/
/*  top: 30px;*/
/*  left: -70px;*/
/*}*/
.el-icon-plus.current {
  line-height: 180px;
  height: 180px;
  width: 150px;
  border: 1px dashed #cccccc;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 30px;
}

</style>
