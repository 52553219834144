<template>
    <div>
        <el-form ref="formRef" :model="form" :rules="rules" label-width="auto">
            <el-form-item label="身份证号" prop="idCard">
                <el-input v-model="form.idCard" placeholder="请输入学生身份证号" clearable @blur="changeIdCard"></el-input>
            </el-form-item>
            <el-form-item label="学生姓名" prop="studentName">
                <el-input v-model="form.studentName" disabled></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="sexName">
                <el-input v-model="form.sexName" disabled></el-input>
            </el-form-item>
            <el-form-item label="学校" prop="campusName">
                <el-input v-model="form.campusName" disabled></el-input>
            </el-form-item>
            <el-form-item label="余额" prop="vendingBalance">
                <el-input v-model="form.vendingBalance" disabled></el-input>
            </el-form-item>
            <el-form-item label="提现金额" prop="transactionAmount">
                <el-input v-model="form.transactionAmount" clearable @blur="changeMoney"></el-input>
            </el-form-item>
            <el-form-item label="提现后余额" prop="money">
                <el-input v-model="form.money"disabled></el-input>
            </el-form-item>
            <el-form-item label="提现手机号" prop="payeePhone">
                <el-input v-model="form.payeePhone" clearable></el-input>
            </el-form-item>
            <el-form-item label="上传凭证" prop="voucherPhoto">
                <el-upload
                    ref="uploadDemo"
                    class="avatar-uploader"
                    :action="action"
                    :headers="headers"
                    accept="image/*"
                    :file-list="form.voucherPhoto"
                    :limit="9"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :on-exceed="handleExceed"
                    :before-remove="handleRemove"
                    list-type="picture-card"
                >
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">最多上传九张图片，每张最大20MB。</div>
                </el-upload>
            </el-form-item>
        </el-form>
        <div style="text-align: center">
            <xk-button type="primary" @click="submit">确定</xk-button>
            <xk-button type="info" @click="close">取消</xk-button>
        </div>
    </div>
</template>

<script>
import { getToken } from "@/libs/auth";

export default {
    name: "withdrawalFormData",
    props: {
        form: {
            type: Object
        },
        rules: {
            type: Object
        }
    },
    computed: {
        action() {
            return 'api/operate/common/uploadFile'
        },
        headers() {
            return {
                'Authorization': getToken()
            }
        },
    },
    methods: {
        handleAvatarSuccess(res, file, fileList) {
            if (res.code === '200') {
                this.form.voucherPhoto = fileList
            }
        },
        beforeAvatarUpload(file) {
            const isImage = file.type.split('/')[0] === 'image';
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isImage) {
                this.$message.error('只允许上传图片!');
                return false
            }
            if (!isLt20M) {
                this.$message.error('上传图片大小不能超过 20MB!');
                return false
            }
        },
        handleExceed() {
           this.$message.warning('最多上传九张图片')
        },
        handleRemove(file, fileList) {
            const index = fileList.findIndex(i => i.uid === file.uid)
            if (index > -1) {
                this.form.voucherPhoto.splice(index, 1)
            }
        },
        submit() {
            this.$refs.formRef.validate((val) => {
                if (val) {
                    this.$emit('submit')
                }
            })
        },
        close() {
            this.$emit('close')
        },
        changeIdCard(e) {
            this.$refs.formRef.validateField('idCard', (val) => {
                if (!val) {
                    if (this.form.transactionAmount) {
                        this.form.transactionAmount = ''
                        this.form.money = ''
                    }
                    // 身份证号校验通过
                    this._fet('/parent/parentInfo/getStuByIdCard', {
                        idCard: this.form.idCard,
                        schoolId: this.form.schoolId
                    }).then(r => {
                        if (r.data.code === '200') {
                            if (JSON.stringify(r.data.data) === '{}') {
                                this.clearInfo()
                                this.$message.error('学生不存在')
                            } else {
                                const keyList = ['studentId', 'onlyCode', 'campusId', 'vendingBalance', 'studentName', 'sexName', 'campusName']
                                keyList.forEach((item) => {
                                    this.form[item] = r.data.data[item]
                                })
                            }
                        } else if (r.data && r.data.msg) {
                            this.clearInfo()
                            this.$message.error(r.data.msg)
                        }
                    }).catch(() => {
                        this.clearInfo()
                    })
                } else {
                    // 身份证号校验未通过
                    this.clearInfo()
                }
            })
        },
        changeMoney() {
            this.$refs.formRef.validateField('transactionAmount', (val) => {
                if (!val) {
                    this.form.money = (this.form.vendingBalance - this.form.transactionAmount).toFixed(2)
                }
            })
        },
        clearInfo() {
            const list = Object.keys(this.form).filter(i => !['idCard', 'schoolId'].includes(i))
            list.forEach((key) => {
                if (key === 'voucherPhoto') {
                    this.form[key] = []
                } else {
                    this.form[key] = ''
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
