<template>
  <el-row class="table-wrapper">
    <el-col :span="12" class="left-wrapper">
      <div class="left-title">系统标准字段名称</div>
      <div v-for="(item, index) in leftListComputed" :key="index" class="left-content">
        <span v-show="item.required" class="required">*</span>
        <span>{{ item.label }}</span>

        <el-dropdown v-if="item.type" trigger="click" @command="typeChange($event, item)">
          <div class="type">
            {{ item.type }}<i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="标准值">标准值</el-dropdown-item>
            <el-dropdown-item v-if="item.label !== '班级'" command="更新值">更新值</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-col>
    <el-col :span="12" class="right-wrapper">
      <div class="right-title">导入文件表头字段</div>
      <div v-for="(item, index) in systemExcelHeaderComputed" :key="index" class="right-content">
        <el-select v-model="keyMap[item.label]" filterable clearable :span="12" @change="change" class="right-select">
          <el-option v-for="(item, index) in  uploadExcelHeader" :disabled="item.disabled" :key="index" :value="item.label" :label="item.label" />
        </el-select>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    importForm: {
      type: Object,
    },
    systemExcelHeader: {
      type: Array
    },
    uploadExcelHeader: {
      type: Array
    },
    keyMap: {
      type: Object
    }
  },
  data() {
    return {
      leftList: []
    }
  },
  computed: {
    leftListComputed() {
      return this._.uniqBy(this.leftList, 'label')
    },
    systemExcelHeaderComputed() {
      return this._.uniqBy(this.systemExcelHeader, 'label')
    }
  },
  watch: {
    systemExcelHeader: {
      handler() {
        let leftList = this.cloneDeep(this.systemExcelHeader)
        this.leftList = leftList.map(e => {
          let type = null
          if(this.importForm.standardTextList.includes(e.label)) {
            type = '标准值'
          }else if(this.importForm.renewTextList.includes(e.label)) {
            type = '更新值'
          }
          return Object.assign({
            type: type
          }, e)
        })
        console.log('this.leftList', this.leftList)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    cloneDeep(data) {
      return JSON.parse(JSON.stringify(data))
    },
    change(data) {
      this.$eventDispatch('importSelChange', data)
    },
    typeChange(type, item) {
      console.log(type, item)
      item.type = type
      this.$eventDispatch('headerTypeChange', this.leftList)
    }
  }
}
</script>

<style scoped lang="scss">
.table-wrapper {
  background: #FCFCFC;
  border: 1px solid #E1E3E6;
  margin: 0 10%;
  .left-wrapper {
    border-right: 1px solid #E6E6E6;
    padding: 0 28px;
    margin: 16px 0 21px 0;
    .left-title {
      font-size: 14px;
      font-weight: bold;
      color: #737373;
      line-height: 40px;
    }
    .left-content {
      height: 46px;
      color: #878787;
      font-size: 14px;
      border-bottom: 1px dashed #E6E6E6;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;

      .required {
        color: #F56C6C;
        padding-right: 5px;
      }

      .el-dropdown {
        margin-left: auto;
      }
      .type {
        border: 1px solid #E6E6E6;
        border-radius: 2px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #737373;
        padding: 0 10px;
        cursor: pointer;
      }
    }
  }
  .right-wrapper {
    padding: 0 28px;
    margin: 16px 0 21px 0;
    .right-title {
      font-size: 14px;
      font-weight: bold;
      color: #499DF2;
      line-height: 40px;
    }
    .right-select {
      width: 100%;
      margin-top: 10px;
    }
    .right-content {
      height: 46px;
    }
  }
}
</style>
